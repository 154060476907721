import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    padding: 15,
    minHeight: 52,
    lineHeight: "1.2em"
  },
  published: {
    borderLeftColor: green[500],
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    backgroundColor: green[50]
  },
  unpublished: {
    borderLeftColor: orange[500],
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    backgroundColor: yellow[50]
  }
});

const Crosswords = ({ values = [] }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Typography color="textSecondary">Ostatnio dodane krzyżówki</Typography>
        <Typography variant="h5" component="h2">
          <List dense={true}>
            {values.map(record => (
              <ListItem
                key={record.id}
                button
                component={Link}
                to={`/crosswords/${record.id}`}
                alignItems="flex-start"
                className={
                  record.publicationDate
                    ? classes.published
                    : classes.unpublished
                }
              >
                <ListItemText
                  primary={
                    record.title +
                    " (Dodano: " +
                    new Date(record.created).toLocaleString("pl-PL") +
                    ")"
                  }
                  secondary={record.shortDescription}
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            ))}
          </List>
        </Typography>
      </Card>
    </div>
  );
};

export default Crosswords;
