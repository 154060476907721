import React from "react";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useDataProvider, useRefresh } from "react-admin";

const PublishButton = ({ record, resource }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const publish = () => {
    dataProvider
      .update(resource + "/publish", {
        data: {
          id: record.id,
          isPublicated: true
        }
      })
      .then(() => {
        refresh();
      });
  };

  const unpublish = () => {
    dataProvider
      .update(resource + "/publish", {
        data: {
          id: record.id,
          isPublicated: false
        }
      })
      .then(() => {
        refresh();
      });
  };
  return record.publicationDate ? (
    <Button color="primary" onClick={unpublish}>
      <VisibilityOffIcon style={{ paddingRight: "6.5px" }} />
      Wycofaj publikację
    </Button>
  ) : (
    <Button color="primary" onClick={publish}>
      <VisibilityIcon style={{ paddingRight: "6.5px" }} />
      Opublikuj
    </Button>
  );
};

export default PublishButton;
