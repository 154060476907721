import { EventList } from "./EventList";
import { EventEdit } from "./EventEdit";
import { EventCreate } from "./EventCreate";
import EventIcon from "@material-ui/icons/Event";

export default {
  list: EventList,
  edit: EventEdit,
  create: EventCreate,
  icon: EventIcon
};
