const local = {
  client: {
    URL: "http://localhost:3000"
  },
  apiGateway: {
    URL: "http://localhost:5002/api"
  },
  staticImagesGateway: {
    URL: "http://localhost:5002/staticimages"
  }
};

const dev = {
  client: {
    URL: "https://rokroslinclient.adrianrydzynski.now.sh"
  },
  apiGateway: {
    URL: "https://dev.adminapi.rokroslin.ovh/api"
  },
  staticImagesGateway: {
    URL: "https://dev.adminapi.rokroslin.ovh/staticimages"
  }
};

const prod = {
  client: {
    URL: "https://rokroslin.pl"
  },
  apiGateway: {
    URL: "https://adminapi.rokroslin.ovh/api"
  },
  staticImagesGateway: {
    URL: "https://adminapi.rokroslin.ovh/staticimages"
  }
};

const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : local;

export default {
  ...config
};
