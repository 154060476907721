import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  DateTimeInput,
  FileField,
  FileInput,
  NumberInput,
  Edit
} from "react-admin";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import FileToolbar from "./components/FileToolbar";

const FileTitle = ({ record }) => {
  return (
    <span>
      {record
        ? record.publicationDate
          ? `"${record.title}"`
          : `"Wersja robocza: ${record.title}"`
        : ""}
    </span>
  );
};

export const FileEdit = props => (
  <Edit undoable={false} title={<FileTitle />} {...props}>
    <TabbedForm
      toolbar={<FileToolbar redirect="list" />}
      validate={validateFile}
    >
      <FormTab label="Informacje">
        <NumberInput
          label="Id"
          disabled
          source="id"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Utworzono"
          disabled
          source="created"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Zmodyfikowano"
          disabled
          source="modified"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput label="Opublikowano" disabled source="publicationDate" />
        <Divider />
        <TextInput label="Tytuł" fullWidth source="title" />
        <Divider />
        <IsPublicatedRadioButtonGroup />
      </FormTab>
      <FormTab label="Plik">
        <FileInput source="files" label="Plik">
          <FileField source="path" title="title" />
        </FileInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const validateFile = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.files || values.files.length === 0) {
    errors.files = ["Plik jest wymagany"];
  } else {
    if (values.files.rawFile) {
      if (values.files.rawFile.size > 20000000) {
        errors.files = ["Pliki mogą posiadać rozmiar maksymalnie 20mb"];
      }
      if (values.files.rawFile.type === "application/x-msdownload") {
        errors.files = ["Pliki nie mogą posiadać rozszerzeń: [.exe], [.dll]"];
      }
    }
  }
  return errors;
};
