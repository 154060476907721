import React from "react";
import { Toolbar } from "react-admin";
import QuizSaveButton from "./QuizSaveButton";

const QuizToolbar = props => (
  <Toolbar {...props}>
    <QuizSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default QuizToolbar;
