import React from "react";
import { Toolbar } from "react-admin";
import TriviaSaveButton from "./TriviaSaveButton";

const TriviaToolbar = props => (
  <Toolbar {...props}>
    <TriviaSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default TriviaToolbar;
