import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton, useNotify } from "react-admin";
import CrosswordLayoutGenerator from "crossword-layout-generator";

const CrosswordSaveButton = ({
  handleSubmitWithRedirect,
  redirect,
  ...props
}) => {
  const form = useForm();
  const notify = useNotify();

  const shuffle = a => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };

  const handleClick = useCallback(() => {
    if (
      form.getFieldState("words").value &&
      form.getFieldState("words").value.length > 0
    ) {
      const input = JSON.parse(
        JSON.stringify(form.getFieldState("words").value)
      );
      let isValid = true;
      input.forEach(element => {
        if (!element.answer || !element.clue) {
          isValid = false;
        }
      });
      if (isValid) {
        let layoutList = [];

        for (let i = 0; i < 15; i++) {
          let layout;
          let ownInput = JSON.parse(JSON.stringify(input));
          if (i === 0) {
            layout = CrosswordLayoutGenerator.generateLayout(ownInput);
          } else {
            layout = CrosswordLayoutGenerator.generateLayout(shuffle(ownInput));
          }

          let badResults = []
          let goodResultsCounter = 0;

          layout.result.forEach(el => {
            if (el.orientation === 'none') {
              badResults.push(el.answer);
            } else {
              goodResultsCounter++;
            }
          });

          layoutList.push({
            layout: layout,
            goodResultsCounter,
            badResults,
            input: ownInput
          })
        }

        let optimalLayout = layoutList[0];

        layoutList.forEach(currentLayout => {
          if (currentLayout.goodResultsCounter > optimalLayout.goodResultsCounter) {
            optimalLayout = currentLayout;
          }
        });

        let layout = optimalLayout.layout;

        let rows = layout.rows;
        let cols = layout.cols;
        let table = layout.table;

        form.change("rows", rows);
        form.change("cols", cols);
        form.change("table", table);
        form.change("words", optimalLayout.input);

        if(optimalLayout.badResults.length) {
          alert(`Stworzono krzyżówkę z następującej ilości haseł: ${optimalLayout.goodResultsCounter}.\nNastępujące hasła nie zostały zawarte: ${optimalLayout.badResults.join(', ')}`)
        }
      }
    }

    handleSubmitWithRedirect(redirect);
    // eslint-disable-next-line
  }, [form, notify]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default CrosswordSaveButton;
