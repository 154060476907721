import { NewsList } from "./NewsList";
import { NewsEdit } from "./NewsEdit";
import { NewsCreate } from "./NewsCreate";
import PostAddIcon from "@material-ui/icons/PostAdd";

export default {
  list: NewsList,
  edit: NewsEdit,
  create: NewsCreate,
  icon: PostAddIcon
};
