import { fetchJson } from "./FetchJsonProvider";
import config from "../config";

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(config.apiGateway.URL + "/auth/authorize", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetchJson(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .then(data => {
        localStorage.setItem("accesstoken", data.accessToken.token);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("accesstoken");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("accesstoken");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("accesstoken")
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve()
};
