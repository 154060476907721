import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";
import FileToBase64Converter from "../../shared/components/FileToBase64Converter";

const QuizSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
  const form = useForm();

  const setupImages = element =>
    new Promise((resolve, reject) => {
      if (element.images) {
        let newImages = [element.images].filter(p => p.rawFile instanceof File);
        let formerImages = [element.images].filter(
          p => !(p.rawFile instanceof File)
        );

        if (newImages && newImages.length > 0) {
          Promise.all(newImages.map(FileToBase64Converter))
            .then(base64Images =>
              base64Images.map(result => ({
                base64File: result.file64
              }))
            )
            .then(transformedNewImages => {
              if (transformedNewImages) {
                element.base64File = transformedNewImages[0].base64File;
              }
              resolve(element);
            });
        } else {
          if (
            formerImages &&
            formerImages.length > 0 &&
            formerImages[0] &&
            formerImages[0].length > 0
          ) {
            element.imagePath = formerImages[0][0].path;
            resolve(element);
          } else {
            resolve(element);
          }
        }
      } else {
        resolve(element);
      }
    });

  const handleClick = useCallback(() => {
    let questions = form.getFieldState("questions").value;
    if (questions && questions.length > 0) {
      Promise.all(questions.map(setupImages)).then(() => {
        handleSubmitWithRedirect(redirect);
      });
    } else {
      handleSubmitWithRedirect(redirect);
    }

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default QuizSaveButton;
