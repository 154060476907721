import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";
import FileToBase64Converter from "../../shared/components/FileToBase64Converter";

const ArticleSaveButton = ({
  handleSubmitWithRedirect,
  redirect,
  ...props
}) => {
  const form = useForm();

  const setupImages = () => {
    let images = form.getFieldState("images").value;
    if (images) {
      let newImages = images.filter(p => p.rawFile instanceof File);
      let formerImages = images.filter(p => !(p.rawFile instanceof File));

      Promise.all(newImages.map(FileToBase64Converter))
        .then(base64Images =>
          base64Images.map(result => ({
            base64File: result.file64
          }))
        )
        .then(transformedNewImages => {
          form.change("newImages", transformedNewImages);
          form.change("formerImages", formerImages);
          handleSubmitWithRedirect(redirect);
        });
    } else {
      handleSubmitWithRedirect(redirect);
    }
  };

  const handleClick = useCallback(() => {
    setupImages();

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default ArticleSaveButton;
