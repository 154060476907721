import { ArticleList } from "./ArticleList";
import { ArticleEdit } from "./ArticleEdit";
import { ArticleCreate } from "./ArticleCreate";
import DescriptionIcon from "@material-ui/icons/Description";

export default {
  list: ArticleList,
  edit: ArticleEdit,
  create: ArticleCreate,
  icon: DescriptionIcon
};
