import React, { useState, useEffect } from "react";
import { useVersion, useDataProvider } from "react-admin";

import Welcome from "./Welcome";
import News from "./News";
import Articles from "./Articles";
import Events from "./Events";
import GalleryAlbums from "./GalleryAlbums";
import Trivias from "./Trivias";
import Files from "./Files";
import Crosswords from "./Crosswords";
import Quizzes from "./Quizzes";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" }
};

const Dashboard = () => {
  const [state, setState] = useState({});
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchNewsAndIfSucceedThenOtherData = async () => {
    dataProvider
      .getList("news", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          news: data
        }));

        fetchArticles();
        fetchEvents();
        fetchGalleryAlbums();
        fetchTrivias();
        fetchFiles();
        fetchCrosswords();
        fetchQuizzes();
      })
      .catch(error => {});
  };

  const fetchArticles = async () => {
    dataProvider
      .getList("articles", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          articles: data
        }));
      })
      .catch(error => {});
  };

  const fetchEvents = async () => {
    dataProvider
      .getList("events", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          events: data
        }));
      })
      .catch(error => {});
  };

  const fetchGalleryAlbums = async () => {
    dataProvider
      .getList("gallery", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          galleryAlbums: data
        }));
      })
      .catch(error => {});
  };

  const fetchTrivias = async () => {
    dataProvider
      .getList("trivias", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          trivias: data
        }));
      })
      .catch(error => {});
  };

  const fetchFiles = async () => {
    dataProvider
      .getList("files", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          files: data
        }));
      })
      .catch(error => {});
  };

  const fetchCrosswords = async () => {
    dataProvider
      .getList("crosswords", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          crosswords: data
        }));
      })
      .catch(error => {});
  };

  const fetchQuizzes = async () => {
    dataProvider
      .getList("quizzes", {
        sort: { field: "created", order: "DESC" },
        pagination: { page: 1, perPage: 5 }
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          quizzes: data
        }));
      })
      .catch(error => {});
  };

  useEffect(() => {
    fetchNewsAndIfSucceedThenOtherData();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    news,
    articles,
    events,
    trivias,
    files,
    galleryAlbums,
    crosswords,
    quizzes
  } = state;

  return (
    <div>
      <div style={styles.flexColumn}>
        <div style={{ marginBottom: "2em" }}>
          <Welcome />
        </div>
        <div style={styles.flex}>
          <News values={news} />
          <Articles values={articles} />
          <Events values={events} />
        </div>
        <div style={styles.flex}>
          <GalleryAlbums values={galleryAlbums} />
          <Trivias values={trivias} />
          <Files values={files} />
        </div>
        <div style={styles.flex}>
          <Crosswords values={crosswords} />
          <Quizzes values={quizzes} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
