import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import polishMessages from "ra-language-polish";
import { dashboard } from "./dashboard";
import news from "./news";
import articles from "./articles";
import events from "./events";
import gallery from "./gallery";
import galleryFolder from "./gallery-folder";
import trivias from "./trivias";
import crosswords from "./crosswords";
import quizzes from "./quizzes";
import files from "./files";
import dataProvider from "./shared/DataProvider";
import authProvider from "./shared/AuthProvider";
import { createMuiTheme } from "@material-ui/core/styles";

const i18nProvider = polyglotI18nProvider(() => polishMessages, "pl");
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#2C9A47"
    }
  }
});
const App = () => (
  <Admin
    theme={theme}
    dashboard={dashboard}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="news" options={{ label: "Aktualności" }} {...news} />
    <Resource name="articles" options={{ label: "Artykuły" }} {...articles} />
    <Resource name="events" options={{ label: "Wydarzenia" }} {...events} />
    <Resource
      name="gallery-folder"
      options={{ label: "Galeria zdjęć - foldery" }}
      {...galleryFolder}
    />
    <Resource
      name="gallery"
      options={{ label: "Galeria zdjęć - albumy" }}
      {...gallery}
    />
    <Resource name="trivias" options={{ label: "Ciekawostki" }} {...trivias} />
    <Resource
      name="crosswords"
      options={{ label: "Krzyżówki" }}
      {...crosswords}
    />
    <Resource name="quizzes" options={{ label: "Quizy" }} {...quizzes} />
    <Resource
      name="files"
      options={{ label: "Pliki do pobrania" }}
      {...files}
    />
  </Admin>
);

export default App;
