import React from "react";
import { Toolbar } from "react-admin";
import FileSaveButton from "./FileSaveButton";

const FileToolbar = props => (
  <Toolbar {...props}>
    <FileSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default FileToolbar;
