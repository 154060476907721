import React from "react";
import Button from "@material-ui/core/Button";
import config from "../../config";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";

const PreviewButton = ({ record, category }) => {
  const url = config.client.URL + "/" + category + "/" + record.id;
  const openInNewTab = () => {
    window.open(url, "_blank");
  };
  return (
    <Button color="primary" onClick={openInNewTab}>
      <PageviewOutlinedIcon style={{ paddingRight: "6.5px" }} />
      Podgląd
    </Button>
  );
};

export default PreviewButton;
