import React from "react";
import {
  TextInput,
  NumberInput,
  SimpleForm,
  Edit,
  DateTimeInput,
} from "react-admin";
import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
//import { DateTimeInput } from '../shared/components/DateTimePicker';
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import { ColorInput } from "react-admin-color-input";

const EventTitle = ({ record }) => {
  return (
    <span>
      {record
        ? record.publicationDate
          ? `"${record.title}"`
          : `"Wersja robocza: ${record.title}"`
        : ""}
    </span>
  );
};

export const EventEdit = (props) => (
  <Edit undoable={false} title={<EventTitle />} {...props}>
    <SimpleForm validate={validateEvent}>
      <NumberInput
        label="Id"
        disabled
        source="id"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput
        label="Utworzono"
        disabled
        source="created"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput
        label="Zmodyfikowano"
        disabled
        source="modified"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput label="Opublikowano" disabled source="publicationDate" />
      <Divider />
      <TextInput label="Tytuł" fullWidth source="title" />
      <DateTimeInput
        label="Data rozpoczęcia"
        source="startDate"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput label="Data zakończenia" source="endDate" />
      <TextInput label="Miejsce" fullWidth multiline source="location" />
      <RichTextInputWithExtendedToolbar
        label="Opis wydarzenia"
        source="longDescription"
      />
      <ColorInput
        source="color"
        label="Kolor (domyślnie niebieski)"
        picker="Sketch"
      />
      <IsPublicatedRadioButtonGroup />
    </SimpleForm>
  </Edit>
);

const validateEvent = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.startDate) {
    errors.startDate = ["Data rozpoczęcia jest wymagana"];
  }
  if (!values.endDate) {
    errors.endDate = ["Data zakończenia jest wymagana"];
  }
  let startDate = new Date(values.startDate);
  let endDate = new Date(values.endDate);
  if (values.startDate && values.endDate && startDate > endDate) {
    errors.endDate = [
      "Data zakończenia musi być późniejsza od Daty rozpoczęcia",
    ];
  }
  if (!values.location) {
    errors.location = ["Miejsce jest wymagana"];
  } else {
    if (values.location.length > 300) {
      errors.location = ["Miejsce może posiadać maksymalnie 300 znaków"];
    }
  }
  if (!values.longDescription) {
    errors.longDescription = ["Długi opis jest wymagany"];
  }
  return errors;
};
