import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";
import FileToBase64Converter from "../../shared/components/FileToBase64Converter";

const FileSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
  const form = useForm();

  const setupFiles = () => {
    form.change("filePath", null);
    let files = form.getFieldState("files").value;
    if (files) {
      let newFiles = [files].filter(p => p.rawFile instanceof File);
      let formerFiles = [files].filter(p => !(p.rawFile instanceof File));

      if (newFiles && newFiles.length > 0) {
        Promise.all(newFiles.map(FileToBase64Converter))
          .then(base64Files =>
            base64Files.map(result => ({
              base64File: result.file64,
              title: result.title
            }))
          )
          .then(transformedNewFiles => {
            if (transformedNewFiles) {
              form.change("base64File", transformedNewFiles[0].base64File);
              form.change("fileName", transformedNewFiles[0].title);
            }
            handleSubmitWithRedirect(redirect);
          });
      } else {
        if (
          formerFiles &&
          formerFiles.length > 0 &&
          formerFiles[0] &&
          formerFiles[0].length > 0
        ) {
          form.change("filePath", formerFiles[0][0].path);
          handleSubmitWithRedirect(redirect);
        } else {
          handleSubmitWithRedirect(redirect);
        }
      }
    } else {
      handleSubmitWithRedirect(redirect);
    }
  };

  const handleClick = useCallback(() => {
    setupFiles();

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default FileSaveButton;
