import React from "react";
import { TextInput, SimpleForm, Create } from "react-admin";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";

export const GalleryFolderCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateGallery}>
      <TextInput label="Tytuł" fullWidth source="title" />
      <TextInput
        label="Krótki opis"
        multiline
        fullWidth
        source="shortDescription"
      />
      <Divider />
      <IsPublicatedRadioButtonGroup />
    </SimpleForm>
  </Create>
);

const validateGallery = async values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków"
      ];
    }
  }
  return errors;
};
