import React from "react";
import {
  TextInput,
  SimpleForm,
  DateTimeInput,
  NumberInput,
  Edit
} from "react-admin";
import Divider from "../shared/components/CustomDivider";
import PreviewLink from "../shared/components/PreviewLink";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";

const GalleryFolderTitle = ({ record }) => {
  return (
    <span>
      {record
        ? record.publicationDate
          ? `"${record.title}"`
          : `"Wersja robocza: ${record.title}"`
        : ""}
    </span>
  );
};

export const GalleryFolderEdit = props => (
  <Edit undoable={false} title={<GalleryFolderTitle />} {...props}>
    <SimpleForm validate={validateGalleryFolder}>
      <NumberInput
        label="Id"
        disabled
        source="id"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput
        label="Utworzono"
        disabled
        source="created"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput
        label="Zmodyfikowano"
        disabled
        source="modified"
        style={{ float: "left", marginRight: "10px" }}
      />
      <DateTimeInput label="Opublikowano" disabled source="publicationDate" />
      <Divider />
      <TextInput label="Tytuł" fullWidth source="title" />
      <TextInput
        label="Krótki opis"
        multiline
        fullWidth
        source="shortDescription"
      />
      <Divider />
      <IsPublicatedRadioButtonGroup />
      <Divider />
      <PreviewLink category="galeria" />
    </SimpleForm>
  </Edit>
);

const validateGalleryFolder = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków"
      ];
    }
  }
  return errors;
};
