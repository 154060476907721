import { FileList } from "./FileList";
import { FileEdit } from "./FileEdit";
import { FileCreate } from "./FileCreate";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export default {
  list: FileList,
  edit: FileEdit,
  create: FileCreate,
  icon: FileCopyIcon
};
