import { GalleryFolderList } from "./GalleryFolderList";
import { GalleryFolderEdit } from "./GalleryFolderEdit";
import { GalleryFolderCreate } from "./GalleryFolderCreate";
import FolderIcon from "@material-ui/icons/Folder";

export default {
  list: GalleryFolderList,
  edit: GalleryFolderEdit,
  create: GalleryFolderCreate,
  icon: FolderIcon
};
