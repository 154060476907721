import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';

const rowStyle = (record, index, defaultStyle = {}) => {
    if (record.publicationDate) {
        return {
            ...defaultStyle,
            borderLeftColor: green[500],
            borderLeftWidth: 7,
            borderLeftStyle: 'solid',
            backgroundColor: green[50]
        }
    } else {
        return {
            ...defaultStyle,
            borderLeftColor: orange[500],
            borderLeftWidth: 7,
            borderLeftStyle: 'solid',
            backgroundColor: yellow[50]
        };
    }
};

export default rowStyle;