import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  DateTimeInput,
  NumberInput,
  Edit
} from "react-admin";
import Divider from "../shared/components/CustomDivider";
import PreviewLink from "../shared/components/PreviewLink";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import CrosswordToolbar from "./components/CrosswordToolbar";
import SimpleFormIterator from "../shared/components/SimpleFormIterator";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Typography } from "@material-ui/core";

const required = (message = "Wymagane") => value =>
  value ? undefined : message;

const maxLength = (max, message = "Tekst zbyt długi") => value =>
  value && value.length > max ? message : undefined;

const CrosswordTitle = ({ record }) => {
  return (
    <span>
      {record
        ? record.publicationDate
          ? `"${record.title}"`
          : `"Wersja robocza: ${record.title}"`
        : ""}
    </span>
  );
};

export const CrosswordEdit = props => (
  <Edit undoable={false} title={<CrosswordTitle />} {...props}>
    <TabbedForm
      toolbar={<CrosswordToolbar redirect="list" />}
      validate={validateCrossword}
    >
      <FormTab label="Informacje">
        <NumberInput
          label="Id"
          disabled
          source="id"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Utworzono"
          disabled
          source="created"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Zmodyfikowano"
          disabled
          source="modified"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput label="Opublikowano" disabled source="publicationDate" />
        <Divider />
        <TextInput label="Tytuł" fullWidth source="title" />
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
        <Divider />
        <PreviewLink category="krzyzowki" />
      </FormTab>
      <FormTab label="Hasła">
        <Typography style={{ width: "auto" }} variant="body1">
          <InfoOutlinedIcon
            fontSize="small"
            style={{ marginRight: 8, marginBottom: -3, marginTop: 10 }}
          />
          Po zapisaniu krzyżówki kolejność haseł może się zmienić ze względu na
          działanie algorytmu tworzącego krzyżówki!
        </Typography>
        <ArrayInput label="Hasła" source="words">
          <SimpleFormIterator additionalLabel={true}>
            <TextInput
              label="Odpowiedź"
              source="answer"
              fullWidth
              validate={[
                required("Odpowiedź jest wymagana"),
                maxLength(30, "Odpowiedź może posiadać maksymalnie 30 znaków")
              ]}
            />
            <TextInput
              label="Podpowiedź"
              source="clue"
              fullWidth
              validate={[
                required("Podpowiedź jest wymagana"),
                maxLength(
                  150,
                  "Podpowiedź może posiadać maksymalnie 150 znaków"
                )
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const validateCrossword = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 300) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 300 znaków"
      ];
    }
  }
  if (!values.words || values.words.length === 0) {
    errors.words = ["Conajmniej jedna odpowiedź jest wymagana"];
  }
  return errors;
};
