import React from "react";
import RichTextInput from "ra-input-rich-text";

const ToolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block", "link", "video"],

  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme

  ["clean"]
];

const RichTextInputWithExtendedToolbar = ({ label, source }) => {
  return (
    <RichTextInput
      label={label}
      multiline
      source={source}
      toolbar={ToolbarOptions}
    />
  );
};

export default RichTextInputWithExtendedToolbar;
