import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  EditButton,
  DeleteButton
} from "react-admin";
import ListAvatar from "../shared/components/ListAvatar";
import rowStyle from "../shared/styles/ListRowWithAbilityToPublishStyles";
import PreviewButton from "../shared/components/PreviewButton";
import PublishButton from "../shared/components/PublishButton";
import ListActions from "../shared/components/ListActions";

const NewsPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.longDescription }} />
);

export const NewsList = props => (
  <List
    title={"Lista aktualności"}
    {...props}
    bulkActionButtons={false}
    actions={<ListActions />}
    sort={{}}
  >
    <Datagrid expand={<NewsPanel />} rowStyle={rowStyle}>
      <ListAvatar />
      <NumberField label="Id" source="id" />
      <DateField label="Utworzono" source="created" showTime />
      <DateField label="Zmodyfikowano" source="modified" showTime />
      <DateField label="Opublikowano" source="publicationDate" showTime />
      <TextField label="Tytuł" source="title" />
      <TextField label="Krótki opis" source="shortDescription" />
      <PublishButton />
      <PreviewButton category="aktualnosci" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
