import React from "react";
import Typography from "@material-ui/core/Typography";
import config from "../../config";

const PreviewLink = ({ record, category }) => {
  const url = config.client.URL + "/" + category + "/" + record.id;
  return (
    <Typography align="center" style={{ marginTop: "16px" }}>
      <b>Podgląd zapisanego elementu:</b>{" "}
      <a target="_blank" rel="noopener noreferrer" href={url}>
        {url}
      </a>
    </Typography>
  );
};

export default PreviewLink;
