import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  Create
} from "react-admin";
import { Typography } from "@material-ui/core";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import GalleryAlbumToolbar from "./components/GalleryAlbumToolbar";

export const GalleryAlbumCreate = props => (
  <Create {...props}>
    <TabbedForm
      toolbar={<GalleryAlbumToolbar redirect="edit" />}
      validate={validateGallery}
    >
      <FormTab label="Informacje">
        <ReferenceInput
          label="Folder"
          source="folderId"
          reference="gallery-folder"
          allowEmpty
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput label="Tytuł" fullWidth source="title" />
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
        <Typography variant="subtitle2" fullWidth>
          Pamiętaj, że jeśli album znajduje się w folderze to folder także musi
          zostać opublikowany by był widoczny na stronie!
        </Typography>
      </FormTab>
      <FormTab label="Zdjęcia">
        <ImageInput
          source="images"
          label="Zdjęcie (Uwaga: Pierwsze zdjęcie stanowić będzie okładkę albumu. W przypadku wgrywania wielu zdjęć jednocześnie ich kolejność będzie równoznaczna z kolejnością w albumie!)"
          accept="image/*"
          multiple
        >
          <ImageField source="path" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const validateGallery = async values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków"
      ];
    }
  }
  if (!values.images || values.images.length === 0) {
    errors.images = ["Conajmniej jedno zdjęcie jest wymagane"];
  } else {
    await values.images.map(image => {
      if (image.rawFile && image.rawFile.size > 20000000) {
        return (errors.images = [
          "Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."
        ]);
      }
      return true;
    });
  }
  return errors;
};
