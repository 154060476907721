import React from "react";
import Button from "@material-ui/core/Button";
import { CreateButton, ExportButton } from "react-admin";
import { useRedirect } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import SortIcon from "@material-ui/icons/Sort";

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => {
  const redirect = useRedirect();

  const resetSorting = () => {
    redirect(basePath);
    window.location.reload();
  };

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <Button color="primary" onClick={resetSorting}>
        <SortIcon style={{ paddingRight: "6.5px" }} />
        Resetuj sortowanie
      </Button>
    </Toolbar>
  );
};

export default ListActions;
