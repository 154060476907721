import { QuizList } from "./QuizList";
import { QuizEdit } from "./QuizEdit";
import { QuizCreate } from "./QuizCreate";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

export default {
  list: QuizList,
  edit: QuizEdit,
  create: QuizCreate,
  icon: QuestionAnswerIcon
};
