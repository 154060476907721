import React from "react";
import { Toolbar } from "react-admin";
import NewsSaveButton from "./NewsSaveButton";

const NewsToolbar = props => (
  <Toolbar {...props}>
    <NewsSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default NewsToolbar;
