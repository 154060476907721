import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  DateTimeInput,
  ImageField,
  ImageInput,
  NumberInput,
  Edit,
} from "react-admin";
import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
import PreviewLink from "../shared/components/PreviewLink";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import { Typography } from "@material-ui/core";
import TriviaToolbar from "./components/TriviaToolbar";

const TriviaTitle = ({ record }) => {
  return (
    <span>
      {record
        ? record.publicationDate
          ? `"${record.title}"`
          : `"Wersja robocza: ${record.title}"`
        : ""}
    </span>
  );
};

export const TriviaEdit = (props) => (
  <Edit undoable={false} title={<TriviaTitle />} {...props}>
    <TabbedForm
      toolbar={<TriviaToolbar redirect="list" />}
      validate={validateTrivia}
    >
      <FormTab label="Informacje">
        <NumberInput
          label="Id"
          disabled
          source="id"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Utworzono"
          disabled
          source="created"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Zmodyfikowano"
          disabled
          source="modified"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput label="Opublikowano" disabled source="publicationDate" />
        <Divider />
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          Czy wiesz, że...
        </Typography>
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <RichTextInputWithExtendedToolbar
          label="Długi opis"
          source="longDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
        <Divider />
        <PreviewLink category="ciekawostki" />
      </FormTab>
      <FormTab label="Zdjęcie">
        <ImageInput source="images" label="Zdjęcie" accept="image/*">
          <ImageField source="path" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const validateTrivia = (values) => {
  const errors = {};
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków",
      ];
    }
  }
  if (!values.longDescription) {
    errors.longDescription = ["Długi opis jest wymagany"];
  }
  if (
    values.images &&
    values.images.rawFile &&
    values.images.rawFile.size > 20000000
  ) {
    errors.images = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
  }
  return errors;
};
