import { TriviaList } from "./TriviaList";
import { TriviaEdit } from "./TriviaEdit";
import { TriviaCreate } from "./TriviaCreate";
import HelpIcon from "@material-ui/icons/Help";

export default {
  list: TriviaList,
  edit: TriviaEdit,
  create: TriviaCreate,
  icon: HelpIcon
};
