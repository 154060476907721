import React from "react";
import { RadioButtonGroupInput } from "react-admin";

const IsPublicatedRadioButtonGroup = ({ record }) => {
  const required = (message = "Wymagane") => value =>
    value !== undefined ? undefined : message;

  return (
    <RadioButtonGroupInput
      label="Wersja wpisu"
      source="isPublicated"
      fullWidth
      choices={[
        { id: true, name: "Publikacja" },
        { id: false, name: "Wersja robocza" }
      ]}
      style={{ marginTop: "16px" }}
      validate={[required("Należy zaznaczyć jedną z opcji")]}
    />
  );
};

export default IsPublicatedRadioButtonGroup;
