import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  ImageInput,
  ImageField,
  SimpleFormIterator,
  Create
} from "react-admin";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import QuizToolbar from "./components/QuizToolbar";

const required = (message = "Wymagane") => value =>
  value ? undefined : message;

const maxLength = (max, message = "Tekst zbyt długi") => value =>
  value && value.length > max ? message : undefined;

export const QuizCreate = props => (
  <Create {...props}>
    <TabbedForm
      toolbar={<QuizToolbar redirect="edit" />}
      validate={validateQuiz}
    >
      <FormTab label="Informacje">
        <TextInput label="Tytuł" fullWidth source="title" />
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
      </FormTab>
      <FormTab label="Pytania">
        <ArrayInput label="Pytania" source="questions">
          <SimpleFormIterator>
            <TextInput
              label="Pytanie"
              source="topic"
              fullWidth
              validate={[
                required("Pytanie jest wymagane"),
                maxLength(300, "Pytanie może posiadać maksymalnie 300 znaków")
              ]}
            />
            <TextInput
              label="Prawidłowa odpowiedź"
              source="correctAnswer"
              fullWidth
              validate={[
                required("Prawidłowa odpowiedź jest wymagana"),
                maxLength(
                  150,
                  "Prawidłowa odpowiedź może posiadać maksymalnie 150 znaków"
                )
              ]}
            />
            <TextInput
              label="Pierwsza błędna odpowiedź"
              source="firstWrongAnswer"
              fullWidth
              validate={[
                required("Pierwsza błędna odpowiedź jest wymagana"),
                maxLength(
                  150,
                  "Pierwsza błędna odpowiedź może posiadać maksymalnie 150 znaków"
                )
              ]}
            />
            <TextInput
              label="Druga błędna odpowiedź"
              source="secondWrongAnswer"
              fullWidth
              validate={[
                maxLength(
                  150,
                  "Druga błędna odpowiedź może posiadać maksymalnie 150 znaków"
                )
              ]}
            />
            <TextInput
              label="Trzecia błędna odpowiedź"
              source="thirdWrongAnswer"
              fullWidth
              validate={[
                maxLength(
                  150,
                  "Trzecia błędna odpowiedź może posiadać maksymalnie 150 znaków"
                )
              ]}
            />
            <ImageInput source="images" label="Zdjęcie" accept="image/*">
              <ImageField source="path" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const validateQuiz = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków"
      ];
    }
  }
  if (!values.questions || values.questions.length === 0) {
    errors.questions = ["Conajmniej jedno pytanie jest wymagane"];
  }
  return errors;
};
