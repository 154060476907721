import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  Create
} from "react-admin";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import CrosswordToolbar from "./components/CrosswordToolbar";

const required = (message = "Wymagane") => value =>
  value ? undefined : message;

const maxLength = (max, message = "Tekst zbyt długi") => value =>
  value && value.length > max ? message : undefined;

export const CrosswordCreate = props => (
  <Create {...props}>
    <TabbedForm
      toolbar={<CrosswordToolbar redirect="edit" />}
      validate={validateCrossword}
    >
      <FormTab label="Informacje">
        <TextInput label="Tytuł" fullWidth source="title" />
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
      </FormTab>
      <FormTab label="Hasła">
        <ArrayInput label="Hasła" source="words">
          <SimpleFormIterator>
            <TextInput
              label="Odpowiedź"
              source="answer"
              fullWidth
              validate={[
                required("Odpowiedź jest wymagana"),
                maxLength(30, "Odpowiedź może posiadać maksymalnie 30 znaków")
              ]}
            />
            <TextInput
              label="Podpowiedź"
              source="clue"
              fullWidth
              validate={[
                required("Podpowiedź jest wymagana"),
                maxLength(
                  150,
                  "Podpowiedź może posiadać maksymalnie 150 znaków"
                )
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const validateCrossword = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 300) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 300 znaków"
      ];
    }
  }
  if (!values.words || values.words.length === 0) {
    errors.words = ["Conajmniej jedna odpowiedź jest wymagana"];
  }
  return errors;
};
