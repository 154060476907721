import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  EditButton,
  ReferenceManyField,
  SingleFieldList,
  ChipField
} from "react-admin";
import rowStyle from "../shared/styles/ListRowWithAbilityToPublishStyles";
import PreviewButton from "../shared/components/PreviewButton";
import PublishButton from "../shared/components/PublishButton";
import ListActions from "../shared/components/ListActions";
import DeleteButtonWithConfirmation from "../shared/components/DeleteButtonWithConfirmation";

export const GalleryFolderList = props => (
  <List
    title={"Lista folderów albumów zdjęć"}
    {...props}
    bulkActionButtons={false}
    actions={<ListActions />}
    sort={{}}
  >
    <Datagrid rowStyle={rowStyle}>
      <NumberField label="Id" source="id" />
      <DateField label="Utworzono" source="created" showTime />
      <DateField label="Zmodyfikowano" source="modified" showTime />
      <DateField label="Opublikowano" source="publicationDate" showTime />
      <TextField label="Tytuł" source="title" />
      <TextField label="Krótki opis" source="shortDescription" />
      <ReferenceManyField
        label="Albumy podrzędne"
        reference="gallery"
        target="folderId"
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceManyField>
      <PublishButton />
      <PreviewButton category="galeria" />
      <EditButton />
      <DeleteButtonWithConfirmation
        title="Czy na pewno chcesz usunąć ten rekord?"
        message="Usunięcie folderu spowoduje usunięcie znajdujacych się w nim albumów. Jeśli chcesz zachować albumy najpierw przejdź do ich edycji i usuń powiązanie z 'Folderem'."
      />
    </Datagrid>
  </List>
);
