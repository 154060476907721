import React from "react";
import { Toolbar } from "react-admin";
import ArticleSaveButton from "./ArticleSaveButton";

const ArticleToolbar = props => (
  <Toolbar {...props}>
    <ArticleSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default ArticleToolbar;
