import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  FileField,
  FileInput,
  Create
} from "react-admin";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import FileToolbar from "./components/FileToolbar";

export const FileCreate = props => (
  <Create {...props}>
    <TabbedForm
      toolbar={<FileToolbar redirect="edit" />}
      validate={validateFile}
    >
      <FormTab label="Informacje">
        <TextInput label="Tytuł" fullWidth source="title" />
        <Divider />
        <IsPublicatedRadioButtonGroup />
      </FormTab>
      <FormTab label="Plik">
        <FileInput source="files" label="Plik">
          <FileField source="path" title="title" />
        </FileInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const validateFile = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.files || values.files.length === 0) {
    errors.files = ["Plik jest wymagany"];
  } else {
    if (values.files.rawFile) {
      if (values.files.rawFile.size > 20000000) {
        errors.files = ["Pliki mogą posiadać rozmiar maksymalnie 20mb"];
      }
      if (values.files.rawFile.type === "application/x-msdownload") {
        errors.files = ["Pliki nie mogą posiadać rozszerzeń: [.exe], [.dll]"];
      }
    }
  }
  return errors;
};
