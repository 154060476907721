import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  EditButton,
  DeleteButton
} from "react-admin";
import rowStyle from "../shared/styles/ListRowWithAbilityToPublishStyles";
import PublishButton from "../shared/components/PublishButton";
import ListActions from "../shared/components/ListActions";

const EventPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.longDescription }} />
);

export const EventList = props => (
  <List
    title={"Lista wydarzeń"}
    {...props}
    bulkActionButtons={false}
    actions={<ListActions />}
    sort={{}}
  >
    <Datagrid expand={<EventPanel />} rowStyle={rowStyle}>
      <NumberField label="Id" source="id" />
      <DateField label="Utworzono" source="created" showTime />
      <DateField label="Zmodyfikowano" source="modified" showTime />
      <DateField label="Opublikowano" source="publicationDate" showTime />
      <TextField label="Tytuł" source="title" />
      <DateField label="Początek wydarzenia" source="startDate" showTime />
      <DateField label="Koniec wydarzenia" source="endDate" showTime />
      <TextField label="Miejsce" source="location" />
      <PublishButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
