import { GalleryAlbumList } from "./GalleryAlbumList";
import { GalleryAlbumEdit } from "./GalleryAlbumEdit";
import { GalleryAlbumCreate } from "./GalleryAlbumCreate";
import CollectionsIcon from "@material-ui/icons/Collections";

export default {
  list: GalleryAlbumList,
  edit: GalleryAlbumEdit,
  create: GalleryAlbumCreate,
  icon: CollectionsIcon
};
