import React from "react";
import Avatar from "@material-ui/core/Avatar";
import PhotoIcon from "@material-ui/icons/Photo";

const ListAvatar = ({ record, size }) =>
  record.mainImagePath ? (
    <Avatar
      src={`${record.mainImagePath}?size=${size}x${size}`}
      title="IMG"
      size={size}
      style={{
        width: size,
        height: size
      }}
    />
  ) : (
    <Avatar
      size={size}
      style={{
        width: size,
        height: size
      }}
    >
      <PhotoIcon />
    </Avatar>
  );

ListAvatar.defaultProps = {
  size: 36
};

export default ListAvatar;
