import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import config from "../config";

const useStyles = makeStyles({
  media: {
    height: "237px",
    width: "709px",
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto"
  },
  text: {
    "text-align": "center"
  }
});

const url = config.staticImagesGateway.URL;

const Welcome = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardMedia
        image={url + "/MRZR2020_Logo_Horizontal.jpg"}
        className={classes.media}
      />
      <CardContent className={classes.text}>
        <Typography variant="h5" component="h2">
          Witamy w panelu administratora strony internetowej - Międzynarodowego
          Roku Zdrowia Roślin 2020!
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Welcome;
