import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  DateTimeInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  Edit
} from "react-admin";
import { Typography } from "@material-ui/core";
import Divider from "../shared/components/CustomDivider";
import PreviewLink from "../shared/components/PreviewLink";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import GalleryAlbumToolbar from "./components/GalleryAlbumToolbar";

const GalleryTitle = ({ record }) => {
  return (
    <span>
      {record
        ? record.publicationDate
          ? `"${record.title}"`
          : `"Wersja robocza: ${record.title}"`
        : ""}
    </span>
  );
};

export const GalleryAlbumEdit = props => (
  <Edit undoable={false} title={<GalleryTitle />} {...props}>
    <TabbedForm
      toolbar={<GalleryAlbumToolbar redirect="list" />}
      validate={validateGallery}
    >
      <FormTab label="Informacje">
        <NumberInput
          label="Id"
          disabled
          source="id"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Utworzono"
          disabled
          source="created"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput
          label="Zmodyfikowano"
          disabled
          source="modified"
          style={{ float: "left", marginRight: "10px" }}
        />
        <DateTimeInput label="Opublikowano" disabled source="publicationDate" />
        <Divider />
        <ReferenceInput
          label="Folder"
          source="folderId"
          reference="gallery-folder"
          allowEmpty
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput label="Tytuł" fullWidth source="title" />
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
        <Typography variant="subtitle2" fullWidth>
          Pamiętaj, że jeśli album znajduje się w folderze to folder także musi
          zostać opublikowany by był widoczny na stronie!
        </Typography>
        <Divider />
        <PreviewLink category="galeria" />
      </FormTab>
      <FormTab label="Zdjęcia">
        <ImageInput
          source="images"
          label="Zdjęcie (Uwaga: Pierwsze zdjęcie stanowić będzie okładkę albumu. W przypadku wgrywania wielu zdjęć jednocześnie ich kolejność będzie równoznaczna z kolejnością w albumie!)"
          accept="image/*"
          multiple
        >
          <ImageField source="path" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const validateGallery = values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków"
      ];
    }
  }
  if (!values.images || values.images.length === 0) {
    errors.images = ["Conajmniej jedno zdjęcie jest wymagane"];
  }
  return errors;
};
