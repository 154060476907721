import { fetchJson } from "./FetchJsonProvider";
import { stringify } from "query-string";
import config from "../config";
import moment from "moment";

// eslint-disable-next-line
Date.prototype.toJSON = function() {
  return moment(this).format();
};

const apiUrl = config.apiGateway.URL;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("accesstoken");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchJson(url, options);
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sortField: field,
      sortDirection: order,
      pageIndex: JSON.stringify(page - 1),
      itemsPerPage: JSON.stringify(perPage),
      filter: JSON.stringify(params.filter)
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(
          headers
            .get("content-range")
            .split("/")
            .pop(),
          10
        )
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sortField: field,
      sortDirection: order,
      pageIndex: JSON.stringify(page - 1),
      itemsPerPage: JSON.stringify(perPage),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(
        headers
          .get("content-range")
          .split("/")
          .pop(),
        10
      )
    }));
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "DELETE",
      body: JSON.stringify({ id: params.id })
    }).then(({ json }) => ({ data: json }))
};
