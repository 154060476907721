import { CrosswordList } from "./CrosswordList";
import { CrosswordEdit } from "./CrosswordEdit";
import { CrosswordCreate } from "./CrosswordCreate";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";

export default {
  list: CrosswordList,
  edit: CrosswordEdit,
  create: CrosswordCreate,
  icon: ViewComfyIcon
};
